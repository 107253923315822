import { createApp } from "vue";
import store from "./store";
import { defineAsyncComponent, defineComponent } from "vue";

import { appData } from "./mixins/appData";
import { punchout } from "./mixins/punchout";
import { tagmanager } from "./mixins/tagmanager";
import { mapState } from "vuex";

import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper/core";
import "swiper/css";
import "swiper/css/pagination";

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import axios from "axios";

import Rollbar from 'rollbar';


// App main
const main = async () => {
  // Async load the Vue 3 APIs we need from the Vue ESM
  // Create our vue instance
  const app = createApp({
    delimiters: ["${", "}"],
    setup() {
      return {
        modules: [Navigation, Pagination],
      };
    },
    components: {
      AccountDashboard: defineAsyncComponent(() => import(/* webpackChunkName: "Account" */ "@/js/components/account/Dashboard.vue") ),
      AccountAddresses: defineAsyncComponent(() => import(/* webpackChunkName: "AccountAddresses" */ "@/js/components/account/addresses/Addresses.vue")),
      AccountAddressesEdit: defineAsyncComponent(() => import(/* webpackChunkName: "AccountEditAddress" */ "@/js/components/account/addresses/AddressesEdit.vue")),
      AccountAddressesAdd: defineAsyncComponent(() => import(/* webpackChunkName: "AccountAddAddress" */ "@/js/components/account/addresses/AddressesAdd.vue")),
      AccountHistory: defineAsyncComponent(() => import(/* webpackChunkName: "AccountHistory" */ "@/js/components/account/history/History.vue")),
      AccountHistoryDetails: defineAsyncComponent(() => import(/* webpackChunkName: "AccountHistoryDetails" */ "@/js/components/account/history/HistoryDetails.vue")),

      //Approvals components
      AccountApproval: defineAsyncComponent(() => import(/* webpackChunkName: "AccountApprovals" */ "@/js/components/account/approvals/Approval.vue")),
      AccountApprovalDetails: defineAsyncComponent(() => import(/* webpackChunkName: "AccountApprovalDetails" */ "@/js/components/account/approvals/ApprovalDetails.vue")),

      //Users components
      AccountUsers: defineAsyncComponent(() => import(/* webpackChunkName: "AccountUsers" */ "@/js/components/account/users/Users.vue")),
      AccountUsersEdit: defineAsyncComponent(() => import(/* webpackChunkName: "AccountEditUser" */ "@/js/components/account/users/UsersEdit.vue")),
      AccountUsersAdd: defineAsyncComponent(() => import(/* webpackChunkName: "AccountAddUser" */ "@/js/components/account/users/UsersAdd.vue")),

      //Groups components
      AccountGroups: defineAsyncComponent(() => import(/* webpackChunkName: "AccountGroups" */ "@/js/components/account/groups/Groups.vue")),
      AccountGroupsEdit: defineAsyncComponent(() => import(/* webpackChunkName: "AccountEditGroup" */ "@/js/components/account/groups/GroupsEdit.vue")),

      //Orders components
      AccountOrders: defineAsyncComponent(() => import(/* webpackChunkName: "AccountHistory" */ "@/js/components/account/orders/Orders.vue")),
      AccountOrdersDetails: defineAsyncComponent(() => import(/* webpackChunkName: "AccountOrderDetails" */ "@/js/components/account/orders/OrdersDetails.vue")),
      AccountPoints: defineAsyncComponent(() => import(/* webpackChunkName: "AccountPoints" */ "@/js/components/account/Points.vue")),
      AccountSecurity: defineAsyncComponent(() => import(/* webpackChunkName: "AccountSecurity" */ "@/js/components/account/Security.vue")),
      AccountFavorites: defineAsyncComponent(() => import(/* webpackChunkName: "AccountFavorites" */ "@/js/components/account/Favorites.vue")),
      AccountProductkits: defineAsyncComponent(() => import(/* webpackChunkName: "AccountProductkits" */ "@/js/components/account/Productkits.vue")),
      AccountCatalog: defineAsyncComponent(() => import(/* webpackChunkName: "AccountCatalog" */ "@/js/components/account/Catalog.vue")),
      AccountDropdown: defineAsyncComponent(() => import(/* webpackChunkName: "AccountDropdown" */ "@/js/components/AccountDropdown.vue")),
      AddToCart: defineAsyncComponent(() => import(/* webpackChunkName: "AddToCart" */ "@/js/components/productpage/AddToCart.vue")),
      AlternativeProducts: defineAsyncComponent(() => import(/* webpackChunkName: "AlternativeProducts" */ "@/js/components/AlternativeProducts.vue")),
      Basket: defineAsyncComponent(() => import(/* webpackChunkName: "Basket" */ "@/js/components/Basket.vue")),
      BudgetStatus: defineAsyncComponent(() => import(/* webpackChunkName: "BudgetStatus" */ "@/js/components/BudgetStatus.vue")),
      CartItems: defineAsyncComponent(() => import(/* webpackChunkName: "CartItems" */ "@/js/components/checkout/partials/CartItems.vue")),
      CallMe: defineAsyncComponent(() => import(/* webpackChunkName: "CallMe" */ "@/js/components/CallMe.vue")),
      CartSummary: defineAsyncComponent(() => import(/* webpackChunkName: "CartSummary" */ "@/js/components/checkout/partials/CartSummary.vue")),
      CategoryGrid: defineAsyncComponent(() => import( /* webpackChunkName: "CategoryGrid" */ "./components/CategoryGrid.vue")),
      CrossSell: defineAsyncComponent(() => import(/* webpackChunkName: "CrossSell" */ "./components/checkout/partials/CrossSell.vue")),
      CheckoutAddress: defineAsyncComponent(() => import( /* webpackChunkName: "CheckoutAddress" */ "./components/checkout/Address.vue")),
      CheckoutShipping: defineAsyncComponent(() => import(/* webpackChunkName: "CheckoutShipping" */ "./components/checkout/Shipping.vue")),
      CheckoutConfirm: defineAsyncComponent( () => import( /* webpackChunkName: "CheckoutConfirm" */ "./components/checkout/Confirm.vue" ) ),
      CheckoutConfirmation: defineAsyncComponent( () => import(/* webpackChunkName: "CheckoutConfirmation" */ "./components/checkout/Confirmation.vue" )),
      Bundle: defineAsyncComponent( () => import( /* webpackChunkName: "Bundle" */ "./components/productpage/Bundle.vue" )),
      Paginate: defineAsyncComponent( () => import(/* webpackChunkName: "Paginate" */ "./components/Paginate.vue")),
      Product: defineAsyncComponent( () => import( /* webpackChunkName: "Product" */ "./components/productpage/Product.vue")),
      Redeem: defineAsyncComponent(() => import(/* webpackChunkName: "Redeem" */ "./components/checkout/partials/Redeem.vue") ),
      Search: defineAsyncComponent( () => import(/* webpackChunkName: "Search" */ "./components/Search.vue")),
      SiteMenu: defineAsyncComponent(() => import(/* webpackChunkName: "SiteMenu" */ "./components/SiteMenu.vue") ),
      Subtab: defineAsyncComponent( () => import(/* webpackChunkName: "Subtab" */ "./components/Subtab.vue")),
      Tab: defineAsyncComponent( () => import(/* webpackChunkName: "Tab" */ "./components/Tab.vue")),
      SiteSelector: defineAsyncComponent(() => import(/* webpackChunkName: "SiteSelector" */ "./components/SiteSelector.vue" )),
      Subtabs: defineAsyncComponent( () => import(/* webpackChunkName: "Subtabs" */ "./components/Subtabs.vue") ),
      Tabs: defineAsyncComponent(() => import(/* webpackChunkName: "Tabs" */ "./components/Tabs.vue")),
      TabsMultilevel: defineAsyncComponent( () => import( /* webpackChunkName: "TabsMultilevel" */ "./components/TabsMultilevel.vue")),
      WashingInstructions: defineAsyncComponent( () => import( /* webpackChunkName: "WashingInstructions" */ "./components/productpage/WashingInstructions.vue" )),
      LoginForm: defineAsyncComponent( () => import(/* webpackChunkName: "LoginForm" */ "./components/LoginForm.vue")),
      VatSwitcher: defineAsyncComponent(() => import(/* webpackChunkName: "VatSwitcher" */ "./components/VatSwitcher.vue")),
      SkuSwitcher: defineAsyncComponent(() => import(/* webpackChunkName: "SkuSwitcher" */ "./components/SkuSwitcher.vue")),
      NewsletterSignup: defineAsyncComponent(() => import( /* webpackChunkName: "NewsletterSignup" */ "./components/NewsletterSignup.vue")),
      Productkit: defineAsyncComponent(() => import( /* webpackChunkName: "Productkit" */ "./components/Productkit.vue")),
      Slider: defineAsyncComponent(() => import( /* webpackChunkName: "Slider" */ "./components/productpage/Slider.vue")),
      Relogin: defineAsyncComponent(() => import( /* webpackChunkName: "Relogin" */ "./components/Relogin.vue")),
      ToastNotification: defineAsyncComponent(() => import( /* webpackChunkName: "ToastNotification" */ "./components/ToastNotification.vue")),
      // TextClamp: defineAsyncComponent(() => import( /* webpackChunkName: "TextClamp" */ "./components/TextClamp.vue")),
      Swiper,
      SwiperSlide,
      VueTelInput,
    },
    data: () => ({
      inclVat: true,
    }),
    computed: {
      ...mapState({
        cartQty: (state) => state.cart.qty,
        isBasketVisible: (state) => state.cart.isVisible,
        isSiteSelectorVisible: (state) => state.app.isSiteSelectorOpen,
        showCallMe: (state) => state.app.showCallMe,
        csrf: (state) => state.app.csrfToken,
        impersonate: (state) => state.app.impersonate,
        siteUrl: (state) => state.app.siteUrl,
      }),
      shippingReturnUrl(){
        return 'checkout/cart'
      }
    },
    methods: {
      beginCheckout(){
        this.gtmBeginCheckout()
      },
      openCart() {
        store.dispatch("cart/open");
        this.gtmViewCart();
      },
      openCallMe: function () {
          store.commit('app/setCallMeState', true)
      },
      openSiteSelector() {
        store.dispatch("app/openSiteSelector");
      },
      prerenderLink: function (e: Event) {
        const head = document.getElementsByTagName("head")[0];
        const refs = head.childNodes;
        const ref = refs[refs.length - 1];

        const elements = head.getElementsByTagName("link");
        Array.prototype.forEach.call(elements, function (el, i) {
          if ("rel" in el && el.rel === "prerender") {
            el.parentNode.removeChild(el);
          }
        });

        if (ref.parentNode && e.currentTarget) {
          const target: HTMLAnchorElement = <HTMLAnchorElement>e.currentTarget;
          const prerenderTag = document.createElement("link");
          prerenderTag.rel = "prerender";
          prerenderTag.href = target.href;
          ref.parentNode.insertBefore(prerenderTag, ref);
        }
      },
      scrollTo: function(elementId: string){
           var element = document.querySelector(elementId);

          // scroll to element
          if(element){
          element.scrollIntoView({ behavior: "smooth" });
          }
      }
    },
    mounted() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null });

      if(document.querySelector('.l-cart')){
        this.gtmViewCart();
      }
    },
    beforeMount: function () {
      store.commit("app/setTranslations", window.siteData.translations);
      store.commit("app/setSiteUrl", window.siteData.siteUrl);

      this.$store.dispatch("cart/close");
      this.$store.dispatch("app/closeSiteSelector");

      //Get sitedata
      let url = (this.siteUrl) ? this.siteUrl+'services/app/sitedata' : '/services/app/sitedata'
      axios.get(url).then((response) => {
        let data = response.data;
        store.dispatch("cart/updateCart", data.cart);
        store.commit("app/setCsrfToken", data.csrf);
        store.commit("app/setSiteUrl", data.siteUrl);
        store.commit("app/setFavorites", data.favorites);
        store.commit("app/setImpersonateState", data.impersonated);
        store.commit("app/setUserTokenState", data.token);
      });
    },
  });

  app.use(store);
  app.mixin(appData);
  app.mixin(punchout);
  app.mixin(tagmanager);

  // Mount the app
  const vm = app.mount("#app");

  return vm;
};

// Execute async function
main().then((vm) => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
